import React from "react"
import Layout from '../components/layout'

export default () => (
  <Layout>
    <section className="flex flex-col items-center mt-6 w-100">
      <img src="/images/SSB-1979-ChelseaMaggie-Hopp-001-215x300__PHOTOCRED--Maggie_Hopp.jpg" alt="Stefan Brecht by Maggie Hopp" />
      <span className="self-end py-12 mr-12 font-mono">photo credit: Maggie Hopp</span>
    </section>
  </Layout>
)
